import axios from "axios";
import config from "../config/config";
const DOM_TOKEN = "DOM-TOKEN";

const DOMService = {
  login(data) {
    return axios.post("/login", data, {
      baseURL: config.api_url
    });
  },
  verify() {
    return axios.get("/verify", {
      baseURL: config.api_url,
      headers: {
        Token: this.getToken()
      }
    });
  },
  getToken() {
    return window.localStorage.getItem(DOM_TOKEN);
  },
  saveToken(token) {
    window.localStorage.setItem(DOM_TOKEN, token);
  },
  destroyToken() {
    window.localStorage.removeItem(DOM_TOKEN);
  }
};
export default DOMService;
