import DOMService from "../dom.service";
// import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  refresh_token: "",
  isAuthenticated: !!DOMService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      DOMService.login(credentials)
        .then(({ data }) => {
          // console.log("Here what post returns", data);
          context.commit(SET_AUTH, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          if (response.data.data) {
            context.commit(SET_ERROR, response.data.data.error_description);
          } else {
            context.commit(SET_ERROR, response.data.message);
          }
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  // [REGISTER](context, credentials) {
  //   return new Promise(resolve => {
  //     ApiService.post("login", credentials)
  //       .then(({ data }) => {
  //         context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response.data.errors);
  //       });
  //   });
  // },
  [VERIFY_AUTH](context) {
    if (DOMService.getToken()) {
      return DOMService.verify()
        .then(({ data }) => {
          let info = data.data;
          info.access_token = DOMService.getToken();
          context.commit(SET_AUTH, info);
        })
        .catch(() => {
          context.commit(PURGE_AUTH);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  }
  // [UPDATE_PASSWORD](context, payload) {
  //   const password = payload

  //   return ApiService.put('password', password).then(({ data }) => {
  //     context.commit(SET_PASSWORD, data)
  //     return data
  //   })
  // },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = null;
    DOMService.saveToken(state.user.access_token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = null;
    DOMService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
